@import "./common.scss";
@import "colors";

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-Semibold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-Bold.woff2') format('woff2');
  font-weight: bold; // 700
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'Inter', 'Almarai', 'Noto Sans Bengali', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $body-bgc;
}

code {
  font-family: 'Inter', 'Almarai', 'Noto Sans Bengali', sans-serif;
}

/* This will work on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: darkgray #dddddd;
}

/* Target's on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

*::-webkit-scrollbar-track {
  background: #dddddd;
}

*::-webkit-scrollbar-thumb {
  background-color: darkgray;
}

.pos-relative {
  position: relative;
}

/* ============  Start Custom CSS =========== */

/* APP layout */
.app-wrapper {
  display: grid;
  @include fullDisplay;

  .my-sider {
    margin: 16px;
  }

  .app-page {
    margin-right: 16px;

    .page-header {

      .ant-page-header {
        padding-inline: unset;
        padding: 10px 0;
      }

    }

    .page-content {
      overflow-y: auto;
      height: calc(100vh - 152px);
      background-color: $white;
      border-radius: 12px;
    }
  }
}

.ant-select {
  height: 48px;
}

.ant-select-selector {
  background-color: $gray-one !important;
  border-color: $white !important;
}

.ant-radio-button-wrapper {
  height: 44px;
  line-height: 44px;
}

//.ant-btn {
//  padding-left: 32px;
//  padding-right: 32px;
//
//  &:hover {
//    color: $txt-primary-color !important;
//  }
//}
//
//.ant-btn-color-default.ant-btn-variant-outlined.ant-btn-background-ghost {
//  height: 40px !important;
//
//  &:hover {
//    color: $primary-color !important;
//  }
//}
//
//.ant-btn-primary {
//  border: none;
//
//  &:hover {
//    background: $primary-gradient-color !important;
//    color: $white !important;
//  }
//}

.ant-btn-variant-solid:disabled {
  background: $default-btn-bgc !important;

  &:hover {
    background: $default-btn-bgc !important;
    color: #2437573F !important;
  }
}

//.custom-default-btn {
//  border: none;
//}

.text-primary-gradient-color {
  background-image: $primary-gradient-color;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
}

.react-flow__container {
  overflow: visible;
}

.ant-table-pagination {
  align-items: center;

  .ant-pagination-options {
    display: flex;
    align-items: center;
  }
}

.text-ellipsis {
  white-space: nowrap; /* Prevents text from wrapping to the next line */
  overflow: hidden; /* Hides overflow text */
  text-overflow: ellipsis; /* Displays an ellipsis (…) */
  width: 150px;
}

.block-name-ellipsis {
  white-space: nowrap; /* Prevents text from wrapping to the next line */
  overflow: hidden; /* Hides overflow text */
  text-overflow: ellipsis; /* Displays an ellipsis (…) */
  max-width: 250px;
}

//.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover{
//  background-color: red !important;
//}

.form-drawer-root-class {
  .ant-drawer-content {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }
}

.ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
  border-color: $primary-color !important;
}

.ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):active .ant-select-selector {
  border-color: $primary-color !important;
}

.canvas-drag-drop-menu-icon-desc {
  margin: 0;
  padding: 10px;
  border-radius: 4px;
  color: $primary-color;
  background-color: $light-primary-color;
}

.ant-picker-range {
  height: 48px;
  border-color: transparent;

  &:hover {
    border-color: $primary-color;
  }
}